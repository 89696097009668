// ブレイクポイント
const breakPoint = {
	sp: window.getComputedStyle(document.documentElement).getPropertyValue('--breakPointSp'),
	tabPc: window.getComputedStyle(document.documentElement).getPropertyValue('--breakPointTabPc')
};

// selectでURL切り替え
(function(){
	const select = document.querySelectorAll('.js-select-url');
	select.forEach((tgt) => {
		tgt.addEventListener('change', () => {
			document.location.href = tgt.options[tgt.selectedIndex].value;
		});
	});
})();

// Recaptcha v3
(function(){
	const form = document.querySelector('form[data-recaptcha-form]');
	if(!form) return;
	form.addEventListener('submit', onSubmit);
	function onSubmit(e) {
		e.preventDefault();
		grecaptcha.ready(function() {
			grecaptcha.execute('6Lc9TQwkAAAAAJBU_BoWlQAT9jR9gozXMN7fX0vE', {action: 'submit'}).then(function(token){
				var recaptchaToken = document.getElementById('recaptchaToken');
				recaptchaToken.value = token;
				form.submit();
			});
		});
	}
})();	

const serviceTab = (() => {
  const tabwrap = document.querySelector('.service-category__tab');
  const tabs = document.getElementsByClassName('service-category__tab__button');
  const bodys = document.getElementsByClassName('service-category__description');
	if(!tabwrap || !tabs || !bodys) return;
	// 初期位置設定
	const initialNumber = 0;
	tabs[initialNumber].querySelector('.d-tab').classList.add('-active');
	bodys[initialNumber].classList.add('is-show');
	// タブ切り替え
  for(let i = 0; i < tabs.length; i++) {
		tabs[i].addEventListener("click", tabSwitch, false);
  }
	function tabSwitch() {
		// ボタン
		const activeTab = tabwrap.querySelector('.-active');
		activeTab.classList.remove('-active');
		const nextTab = this.querySelector('.d-tab');
		nextTab.classList.add('-active');
		const activeNumber = Number(nextTab.dataset.tab);
		// コンテンツ	
		for(let i = 0; i < bodys.length; i++) {
			if(bodys[i].classList.contains('is-show')) {
				bodys[i].classList.remove('is-show');	
			}
			if(Number(bodys[i].dataset.tab) == activeNumber) {
				bodys[i].classList.add('is-show');	
			}
		}
	}
})();

// カレンダースライダー
const calendarSlider = (() => {

	const target = '.js-calendarSlider';
	const targetElem = document.querySelector(target);
	if(!targetElem) return;
	const slides = targetElem.querySelectorAll('.swiper-slide');
	const shouldSliderDisabled = slides.length <= 1;

	function setHeight() {
		const activeSlide = targetElem.querySelector('.swiper-slide-active');
		targetElem.style.height = '';
		const activeHeight = activeSlide.clientHeight;
		targetElem.style.height = activeHeight + 'px';
	}

	const slider = new Swiper(target, {
		navigation: {
			nextEl: '.calendar-slider__arrow--next',
			prevEl: '.calendar-slider__arrow--prev',
		},
		on: {
			init: function (e) {
				setHeight();
			},
			slideChangeTransitionStart	: function (e) {
				setHeight();
			}
		}
	});
	
	if(shouldSliderDisabled) {
		targetElem.classList.add('is-arrow-none');
		slider.destroy();
	} 

})();